import '../src/global.css';
import 'react-toastify/dist/ReactToastify.css';
import '@datacamp/cdn-ui/dist/style.css';

import { TimezoneProvider } from '@datacamp/cdn-ui';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';

import { LayoutErrorBoundary } from '@components/ErrorBoundary/LayoutErrorBoundary';
import { GoogleAnalytics } from '@components/GoogleAnalytics/GoogleAnalytics';
import { AuthenticatedProviders } from '@components/Layout/AuthenticatedLayout/AuthenticatedProviders';
import { Sentry } from '@components/Layout/Sentry/Sentry';
import { StagingBar } from '@components/Layout/StagingBar/StagingBar';
import { AppThemeProvider } from '@components/Layout/StagingBar/ThemeSwitcher/AppThemeContext';
import { LiveChat } from '@components/LiveChat/LiveChat';
import { Toast } from '@components/Toast/Toast';
import { RoutesProvider } from '@hooks/Routes/RoutesContext';
import { DevToolsContext } from '@hooks/useDevToolsContext';
import { UserProvider } from '@hooks/useUser';
import type { IUserData } from '@lib/prefetchUserData';

interface IProps {
  isLoggedIn?: boolean;
  withDevTools: boolean;
  isSuperlogin?: boolean;
  editEnabled?: boolean;
  csrf?: string;
  user: IUserData;
  accessConfig: Record<string, boolean>;
  defaultTheme: 'dark' | 'light';
}

dayjs.extend(utc);
dayjs.extend(timezone);

const queryClient = new QueryClient();

export type NextPageWithLayout<P, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout<T>;
};

export const App: React.FC<AppPropsWithLayout<IProps>> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? (page => page);

  const page = React.useMemo(() => {
    if (pageProps.isLoggedIn) {
      return (
        <AuthenticatedProviders>{getLayout(<Component {...pageProps} />)}</AuthenticatedProviders>
      );
    }

    return getLayout(<Component {...pageProps} />);
  }, [pageProps.isLoggedIn, pageProps]);

  return (
    <TimezoneProvider>
      <QueryClientProvider client={queryClient}>
        <UserProvider userData={pageProps.user}>
          <DevToolsContext.Provider value={pageProps.withDevTools}>
            <AppThemeProvider defaultTheme={pageProps.defaultTheme}>
              {pageProps?.csrf && (
                <Head>
                  <meta content={pageProps.csrf} name="csrf-token" />
                </Head>
              )}
              <RoutesProvider accessConfig={pageProps.accessConfig}>
                <LayoutErrorBoundary layout="page">{page}</LayoutErrorBoundary>
              </RoutesProvider>
              <Toast />
              {!pageProps.isSuperlogin && <LiveChat />}
              {pageProps.withDevTools && <StagingBar />}
              <Sentry />
            </AppThemeProvider>
          </DevToolsContext.Provider>
          <GoogleAnalytics />
        </UserProvider>
      </QueryClientProvider>
    </TimezoneProvider>
  );
};

export default App;
