import { endPoints } from './Routes/routes';
import { useMutation } from '@hooks/useMutation';

export const useLogout = () => {
  const logoutMutation = useMutation({
    method: 'POST',
    onSuccess: () => {
      if (typeof window.Intercom === 'function') {
        // eslint-disable-next-line new-cap
        window.Intercom('shutdown');
      }
    },
  });

  const onLogout = () => {
    logoutMutation.mutate({ url: endPoints.logout });
  };

  return { onLogout };
};
